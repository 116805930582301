import { Component, Input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import {
  AirtimeOrderDetailsPojo,
  BuyAirtimeControllerService, BuyDataControllerService,
  BuyElectricityControllerService, DataOrderDetailsPojo,
  ElectricityOrderDetailsPojo,
  PaymentControllerService,
  TransactionPojo
} from '../../../../../sdk/util-sdk';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-transaction',
    templateUrl: './transaction.component.html',
    styleUrls: ['./transaction.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        OrderDetailsComponent
    ]
})
export class TransactionComponent  implements OnInit {

  order: any | ElectricityOrderDetailsPojo | AirtimeOrderDetailsPojo | DataOrderDetailsPojo;
  token: TransactionPojo;

  @Input()
  reference: string;

  checkingPayment = false;
  fulfillingOrder = false;

  constructor(
    private paymentController: PaymentControllerService,
    private electricityService: BuyElectricityControllerService,
    private airtimeService: BuyAirtimeControllerService,
    private dataService: BuyDataControllerService
  ) {}

  ngOnInit() {
    this.getPaymentStatus(this.reference);
    this.order = history.state.order;
  }

  getPaymentStatus(paymentReference: string): void {
    this.checkingPayment = true;
    this.paymentController
      .verifyPaymentStatus({ transactionRef: paymentReference, provider: 'SQUAD' })
      .pipe(
        switchMap((paymentVerification: any) => {

          this.checkingPayment = false;
          this.fulfillingOrder = true;

          let apiCall;
          if (this.order.meterDetails) {
            apiCall = this.checkElectricityPurchase();
          } else if (this.order.variationCode) {
            apiCall = this.checkDataPurchase();
          } else {
            apiCall = this.checkAirtimePurchase();
          }
          return apiCall;
        })
      )
      .subscribe({
        next: (token: TransactionPojo) => {
          this.fulfillingOrder = false;
          this.token = token;

        },
        error: () => {
          this.checkingPayment = false;
          this.fulfillingOrder = false;
          console.error("An error occurred during payment verification or fulfillment.");
        }
      });
  }

  checkElectricityPurchase(): Observable<any> {
    return this.electricityService.checkElectricityPurchaseStatus({ paymentReference: this.reference });
  }

  checkAirtimePurchase(): Observable<any> {
    return this.airtimeService.checkAirtimePurchaseStatus({ paymentReference: this.reference });
  }

  checkDataPurchase(): Observable<any> {
    return this.dataService.checkDataPurchaseStatus({paymentReference: this.reference});
  }

}
