import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgClass } from '@angular/common';

@Component({
    selector: 'password-custom-error',
    templateUrl: './password-custom-error.component.html',
    styleUrls: ['./password-custom-error.component.css'],
    standalone: true,
    imports: [
        NgClass
    ]
})
export class PasswordCustomErrorComponent implements OnInit {
  @Input() form: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  handleErrorType(errorType: string) {
    return this.form.get('password').hasError(errorType);
  }

}
