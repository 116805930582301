import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AsyncSubject, from, Observable, Observer } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { mergeMap } from 'rxjs/operators';
import { BrowserInfo, BrowserInformationService } from './browser-information.service';
import { PageManager } from './page-manager';
import {environment} from '../environments/environment';
import moment from "moment";
import { UserAccountService } from './user-account.service';
import { AuthService } from './auth.service';
import { ToastController } from '@ionic/angular';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private _lastSeen!: moment.Moment;
  private browserInfo: BrowserInfo | undefined;
  private static headers: any = {};
  private _httpError: EventEmitter<HttpErrorResponse> = new EventEmitter();

  private keycloakEndpoints = [
    `${environment.keycloakConfig.url}/realms/${environment.keycloakConfig.realm}/protocol/openid-connect/token`,
    `${environment.keycloakConfig.url}/realms/${environment.keycloakConfig.realm}/protocol/openid-connect/logout`
  ];

  constructor(
    private authService: AuthService,
    private toastController: ToastController,
    private browserInfoService: BrowserInformationService
  ) {
    const lastSeen = localStorage.getItem(HttpInterceptorService.name + '.lastSeen');
    if (lastSeen) {
      this._lastSeen = moment.unix(parseInt(lastSeen, 10));
    }
    this.browserInfo = this.browserInfoService.getBrowserInfo();
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const bypassHeaders = this.keycloakEndpoints.some(endpoint => req.url.includes(endpoint));

    if (!bypassHeaders) {
      let token = this.authService.accessToken;
      this._lastSeen = moment();
      localStorage.setItem(HttpInterceptorService.name + '.lastSeen', `${this._lastSeen.valueOf() / 1000}`);
      if (token) {
        const headers: any = { Authorization: `Bearer ${token}` };
        Object.keys(HttpInterceptorService.headers).forEach((header) => {
          if (!HttpInterceptorService.headers[header]) {
            return;
          }
          headers[header] = HttpInterceptorService.headers[header];
        });
        req = req.clone({ setHeaders: headers });
      }
    }

    const handled: Observable<HttpEvent<any>> = next.handle(req);
    const subject: AsyncSubject<HttpEvent<any>> = new AsyncSubject();
    handled.subscribe(subject);
    subject.subscribe((event: HttpEvent<any>) => {
      if (event instanceof HttpErrorResponse) {
        if (event.status === 401) {
          if (this.authService.accessToken) {
            this.authService.clearSession();
          }
          return;
        }
        this._httpError.emit(event);
      }
    }, (err: HttpEvent<any>) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status < 1) {
          this.showToast('Please check your internet connection');
        } else if (err.status === 401) {
          if (this.authService.accessToken) {
            this.authService.clearSession();
          }
          return;
        } else if (err.status === 404) {
          return;
        }
        this._httpError.emit(err);
      }
    });
    return Observable.create((obs: Observer<HttpEvent<any>>) => {
      subject.subscribe(obs);
    });
  }

  async showToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'middle',
      icon: 'checkmark-circle',
      mode: 'ios'
    });
    await toast.present();
  }
}
