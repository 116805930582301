<div id="appCapsule">
  <div class="section mt-2">
    <form class="row" [formGroup]="form">
      <div class="col-6">
        <div class="form-group basic">
          <div class="input-wrapper">
            <select class="form-control custom-select">
              <option value="1">All Categories</option>
              @for (type of services; track $index) {
                <option [value]="type">{{ type | removeUnderscore| titlecase }}</option>
              }
            </select>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group basic">
          <div class="input-wrapper">
            <select class="form-control custom-select">
              <option value="1">All Status</option>
              @for (status of statuses; track $index) {
                <option [value]="status">{{ status | removeUnderscore| titlecase }}</option>
              }
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>
  @defer (when !loadingTransactions) {
    @for (entry of groupedTransactions | keyvalue : sortDescending; track entry) {
      <!-- Transactions -->
      <div class="section">
        <div class="section-title">{{ day(entry.key) }}</div>
        <div class="transactions">
          @for (transaction of entry.value; track $index) {
            <!-- item -->
            <a class="item" (click)="viewDetails(transaction)">
              <div class="detail">
                <img [src]="getImageIcon(transaction)" alt="img" class="image-block imaged w48">
                <div>
                  <strong>{{ transaction.transactionLineItem }}</strong>
                  <p>{{ transaction.serviceHead | uppercase }}</p>
                </div>
              </div>
              <div class="right text-end">
                <div class="price"> {{ transaction?.amount | currency : '₦' : 'symbol' : '1.0-0' }}</div>
                <div class="fontsize-sub" [ngClass]="statusColor(transaction)">
                  {{ transactionStatus(transaction) }}
                </div>
              </div>
            </a>
            <!-- * item -->
          }
        </div>
      </div>
      <!-- * Transactions -->
    }

    @if (hasMore()) {
      <div class="section mt-2 mb-2">
        <a class="btn btn-primary btn-block btn-lg" (click)="loadMore()">Load More</a>
      </div>
    }
  } @placeholder {
    <div class="section">
      <transactions-skeleton />
    </div>
  }

</div>
