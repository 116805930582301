import { Component } from '@angular/core';
import {IonicModule} from "@ionic/angular";

@Component({
    selector: 'side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.css'],
    standalone: true,
    imports: [
        IonicModule
    ]
})
export class SideNavComponent {
}
