import { Component, OnInit } from '@angular/core';
import {
  QueryResultsTransactionPojo,
  TransactionPojo,
  TransactionsControllerService
} from '../../../../../sdk/util-sdk';
import ServiceHeadEnum = TransactionPojo.ServiceHeadEnum;
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { CurrencyPipe, KeyValuePipe, NgClass, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { TransactionsHelper } from '../TransactionsHelper';
import { Utils } from '../../../shared/utils/utils';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RemoveUnderscorePipe } from '../../../shared/pipes/remove-underscore.pipe';
import {
  TransactionsSkeletonComponent
} from '../../../shared/skeletons/transactions-skeleton/transactions-skeleton.component';
import { debounce, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CurrencyPipe,
    KeyValuePipe,
    UpperCasePipe,
    NgClass,
    ReactiveFormsModule,
    TitleCasePipe,
    RemoveUnderscorePipe,
    TransactionsSkeletonComponent
  ]
})
export class TransactionsListComponent implements OnInit {

  form: FormGroup;

  transactions: TransactionPojo[] = [];
  groupedTransactions: Map<string, TransactionPojo[]> = new Map();
  loadingTransactions = false;
  lastQuery: QueryResultsTransactionPojo;

  services = Utils.enumValues(ServiceHeadEnum);
  statuses = Utils.enumValues(TransactionPojo.ServiceTransactionStatusEnum);

  offset = 0;
  limit = 6;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private transactionService: TransactionsControllerService) {
  }

  ngOnInit() {
    this.initForm();
    this.getTransactions();
  }

  initForm(): void {
    this.form = this.fb.group({
      categories: [''],
      status: ['']
    });

    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(300))
      .subscribe({
        next: () => this.getTransactions()
      });
  }

  getTransactions(): void {
    this.loadingTransactions = true;
    this.transactionService.getTransactions({
      limit: this.limit,
      offset: this.offset
    }).subscribe({
      next: (result) => {
        this.transactions = [...this.transactions, ...result.results];
        this.lastQuery = result;
        this.groupTransactionsByDate();
      },
      error: () => {
        console.error('Failed to fetch transactions');
      }
    }).add(() => this.loadingTransactions = false);
  }

  loadMore(): void {
    this.offset += this.limit;
    this.getTransactions();
  }

  groupTransactionsByDate(): void {
    this.groupedTransactions.clear();

    const sortedTransactions = [...this.transactions].sort((a, b) => {
      return new Date(b.transactionTime).getTime() - new Date(a.transactionTime).getTime();
    });

    sortedTransactions.forEach(transaction => {
      const date = new Date(transaction.transactionTime);
      const dateKey = date.toISOString().split('T')[0];
      if (!this.groupedTransactions.has(dateKey)) {
        this.groupedTransactions.set(dateKey, []);
      }
      this.groupedTransactions.get(dateKey)!.push(transaction);
    });
  }

  getImageIcon(transaction: TransactionPojo) {
    return TransactionsHelper.getProviderIcon(transaction);
  }

  transactionStatus(transaction: TransactionPojo): string {
    return TransactionsHelper.transactionStatus(transaction);
  }

  statusColor(transaction: TransactionPojo): string {
    return TransactionsHelper.statusColor(transaction);
  }


  day(dateStr: string): string {
    const today = new Date();
    const date = new Date(dateStr);

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    }

    if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    }

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const diffDays = Math.floor((today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

   /* if (diffDays <= 4) {
      return `${diffDays} days ago`;
    }*/

    if (diffDays < 6) {
      return days[date.getDay()];

    }

    return date.toDateString();
  }

  sortDescending = (a: { key: string }, b: { key: string }): number => {
    return new Date(b.key).getTime() - new Date(a.key).getTime();
  };

  hasMore(): boolean {
    return this.lastQuery?.total > this.transactions.length;
  }

  viewDetails(transaction: TransactionPojo): void {
    this.router.navigate(['/transactions', transaction.invoiceNumber, 'details'], {
      state: {
        transaction
      }
    });
  }
}
