<div id="appCapsule">
  <div class="section mt-2 watermarked-section">
    <div class="card">
      <div class="section">
        <div class="listed-detail mt-1">
          <div class="icon-wrapper">
            <img [src]="icon" alt="image" class="imaged w48 rounded">
          </div>
          <h4 class="text-center mt-1">{{ name }}</h4>
          <h2 class="text-center text-primary mt-2">{{ transaction.amount | currency : '₦' : 'symbol' : '1.0-0' }}</h2>
          <h4 class="text-center d-flex align-items-center justify-content-center transaction-status"
              [class]="statusColor">
            @if(statusIcon) {
              <ion-icon [name]="statusIcon" class="icon"></ion-icon>
            }
            <span>{{ status }}</span>
          </h4>
        </div>
      </div>
    </div>


    <div class="card mt-2">
      @switch (transaction.serviceHead) {
        @case (ServiceHead.ELECTRICITY) {
          <electricity-order-details [order]="electricity" [showLogo]="false"/>
        }
        @case (ServiceHead.AIRTIME) {
          <airtime-order-details [order]="airtime" [showLogo]="false"/>
        }
        @case (ServiceHead.DATA) {
          <data-order-details [order]="data" [showLogo]="false"/>
        }
        @case (ServiceHead.TV) {
          <television-order-details [order]="television" [showLogo]="false"/>
        }
      }
    </div>
  </div>
  <div class="section mt-2">
    <div class="row">
      <div class="col-6">
        <a class="btn btn-lg btn-outline-primary btn-block">Share as image</a>

      </div>
      <div class="col-6">
        <a class="btn btn-lg btn-outline-primary btn-block">
          Share as PDF
        </a>
      </div>
    </div>
  </div>
</div>
