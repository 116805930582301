import { Component, Input, OnInit } from '@angular/core';
import {
  AirtimeOrderDetailsPojo,
  BuyAirtimeControllerService,
  BuyDataControllerService,
  BuyElectricityControllerService, BuyTvControllerService,
  DataOrderDetailsPojo,
  ElectricityOrderDetailsPojo,
  PaymentControllerService,
  TransactionPojo,
  TvOrderDetailsPojo
} from '../../../../../sdk/util-sdk';
import ServiceHead = TransactionPojo.ServiceHeadEnum;
import { CurrencyPipe } from '@angular/common';
import { IonicModule, ToastController } from '@ionic/angular';
import { AirtimeOrderDetailsComponent } from '../../airtime/airtime-order-details/airtime-order-details.component';
import { DataOrderDetailsComponent } from '../../data/data-order-details/data-order-details.component';
import { OrderDetailsComponent } from '../../electricity/order-details/order-details.component';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../../../models/user/user.model';
import { UserAccountService } from '../../../services/user-account.service';
import {
  TelevisionOrderDetailsComponent
} from '../../television/television-order-details/television-order-details.component';
import { TransactionAction, TransactionsHelper } from '../TransactionsHelper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
  standalone: true,
  imports: [
    CurrencyPipe,
    IonicModule,
    AirtimeOrderDetailsComponent,
    DataOrderDetailsComponent,
    OrderDetailsComponent,
    TelevisionOrderDetailsComponent
  ]
})
export class TransactionDetailsComponent implements OnInit {

  @Input() reference: string;
  transaction: TransactionPojo;
  checkingPayment = false;
  fulfillingOrder = false;
  private user: User;

  constructor(
    private router: Router,
    private toastController: ToastController,
    private authService: UserAccountService,
    private dataService: BuyDataControllerService,
    private tvService: BuyTvControllerService,
    private airtimeService: BuyAirtimeControllerService,
    private paymentController: PaymentControllerService,
    private electricityService: BuyElectricityControllerService) {
  }

  ngOnInit() {
    this.getUser();
    this.transaction = history?.state?.transaction;
    this.getPaymentStatus(this.reference);
  }

  getUser(): void {
    this.authService.getUser().subscribe(user => this.user = user);
  }

  getPaymentStatus(paymentReference: string): void {
    this.checkingPayment = true;
    this.paymentController
      .verifyPaymentStatus({ transactionRef: paymentReference, provider: 'SQUAD' })
      .pipe(
        switchMap((transaction: TransactionPojo) => {

          this.checkingPayment = false;
          this.fulfillingOrder = true;

          let apiCall: Observable<TransactionPojo>;
          switch (transaction.serviceHead) {
            case ServiceHead.DATA:
              apiCall = this.checkDataPurchaseApi();
              break;
            case ServiceHead.AIRTIME:
              apiCall = this.checkAirtimePurchaseApi();
              break;
            case ServiceHead.ELECTRICITY:
              apiCall = this.checkElectricityPurchaseApi();
              break;
            case ServiceHead.TV:
              apiCall = this.checkTvPurchaseApi();
              break;
          }
          return apiCall;
        })
      )
      .subscribe({
        next: (transaction: TransactionPojo) => {
          this.fulfillingOrder = false;
          this.transaction = transaction;
        },
        error: () => {
          this.checkingPayment = false;
          this.fulfillingOrder = false;
          console.error('An error occurred during payment verification or fulfillment.');
        }
      });
  }

  checkElectricityPurchaseApi(): Observable<TransactionPojo> {
    return this.electricityService.checkElectricityPurchaseStatus({ paymentReference: this.reference });
  }

  checkAirtimePurchaseApi(): Observable<TransactionPojo> {
    return this.airtimeService.checkAirtimePurchaseStatus({ paymentReference: this.reference });
  }

  checkDataPurchaseApi(): Observable<TransactionPojo> {
    return this.dataService.checkDataPurchaseStatus({ paymentReference: this.reference });
  }

  checkTvPurchaseApi(): Observable<TransactionPojo> {
    return this.tvService.checkTvPurchaseStatus({ paymentReference: this.reference });
  }

  retryPayment(transactionReference: string) {
    if ((window as any).squad) {
      const squadInstance = new (window as any).squad({

        onLoad: (v: any) => console.log('Widget loaded successfully ===>', v),

        onClose: (v: any) => {
          console.log('Widget closed ===>', v);
          this.getPaymentStatus(transactionReference);
        },
        onSuccess: (v: any) => {
          console.log('Payment successful ===>', v);
          this.getPaymentStatus(transactionReference);
        },
        key: environment.squadPublicKey,
        email: this.user.email,
        amount: this.transaction.amount * 100,
        currency_code: 'NGN',
        transaction_ref: transactionReference
      });

      squadInstance.setup();
      squadInstance.open();
    } else {
      console.error('Squad widget not found');
    }
  }

  doAction(): void {
    switch (this.action) {
      case TransactionAction.Pay:
        this.retryPayment(this.reference);
        break;
      case TransactionAction.Refresh:
        this.getPaymentStatus(this.reference);
        break;
      case TransactionAction.Retry:
        break;

    }
  }

  get electricity(): ElectricityOrderDetailsPojo {
    return this.transaction?.electricityOrderDetails;
  }

  get airtime(): AirtimeOrderDetailsPojo {
    return this.transaction?.airtimeOrderDetails;
  }

  get data(): DataOrderDetailsPojo {
    return this.transaction?.dataOrderDetails;
  }

  get television(): TvOrderDetailsPojo {
    return this.transaction?.tvOrderDetails;
  }

  get icon(): string {
    return TransactionsHelper.getProviderIcon(this.transaction);
  }

  get name(): string {
    return TransactionsHelper.name(this.transaction);
  }

  get status(): string {
    return TransactionsHelper.transactionStatus(this.transaction);
  }

  get action(): TransactionAction {
    return TransactionsHelper.action(this.transaction);
  }

  get actionIcon(): string {
    return TransactionsHelper.actionIcon(this.transaction);
  }

  get statusIcon(): string {
    return TransactionsHelper.statusIcon(this.transaction);
  }

  get statusColor(): string {
    return TransactionsHelper.statusColor(this.transaction);
  }

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
    this.showToast('Token copied to clipboard').then(() => console.log('Toast shown'));
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  async showToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'middle',
      icon: 'checkmark-circle',
      mode: 'ios'
    });
    await toast.present();
  }

  protected readonly ServiceHead = ServiceHead;

  viewReceipt(): void {
    this.router.navigate(['transactions', this.reference, 'receipt'], { state: { transaction: this.transaction } });
  }
}
