<div id="appCapsule">

  <div class="section">
    <div class="splash-page mt-5 mb-5">
      <h1>404</h1>
      <h2 class="mb-2">Page Not Found</h2>
      <p>
        Looks like you tried accessing a page that doesn't exist. Please check the URL in the address bar and try again.
      </p>
    </div>
  </div>

  <div class="mt-5 p-3">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-auto">
        <a (click)="goBackHome()" class="btn btn-lg btn-outline-secondary">Go Back</a>
      </div>
    </div>
  </div>

</div>
