import { Component, OnInit } from '@angular/core';
import {
  ElectricityOrderDetailsPojo,
  PaymentControllerService,
  TransactionPojo,
  AirtimeOrderDetailsPojo,
  DataOrderDetailsPojo,
  PaymentInvoiceReference,
  TvOrderDetailsPojo
} from '../../../../../sdk/util-sdk';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { IonicModule } from '@ionic/angular';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { UserAccountService } from '../../../services/user-account.service';
import { User } from '../../../models/user/user.model';
import { UnsubscribeOnDestroyAdapter } from '../../../shared/utils/unsubscribe-on-destroy-adapter';
import { Router } from '@angular/router';
import ServiceHead = TransactionPojo.ServiceHeadEnum;
import { AirtimeOrderDetailsComponent } from '../../airtime/airtime-order-details/airtime-order-details.component';
import { Observable } from 'rxjs';
import { DataOrderDetailsComponent } from '../../data/data-order-details/data-order-details.component';
import {
  TelevisionOrderDetailsComponent
} from '../../television/television-order-details/television-order-details.component';

@Component({
    selector: 'app-payment-method',
    templateUrl: './payment-method.component.html',
    styleUrls: ['./payment-method.component.scss'],
    standalone: true,
    imports: [
      OrderDetailsComponent,
      IonicModule,
      AirtimeOrderDetailsComponent,
      DataOrderDetailsComponent,
      TelevisionOrderDetailsComponent
    ]
})
export class PaymentMethodComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  order: any | ElectricityOrderDetailsPojo | AirtimeOrderDetailsPojo | DataOrderDetailsPojo | TvOrderDetailsPojo;

  selectedMethod: 'card' | 'wallet';

  proceeding = false;
  private user: User;

  constructor(
    private router: Router,
    private location: Location,
    private authService: UserAccountService,
    private paymentService: PaymentControllerService
  ) {
    super();
  }

  ngOnInit() {
    this.order = history?.state?.order
    if (!this.order) this.back();
    this.getUser();
  }

  selectMethod(method: 'card' | 'wallet') {
    this.selectedMethod = method;
  }

  proceed(): void {
    this.createInvoiceAndPay();
  }

  back(): void {
    this.location.back();
  }

  initiateSquadPayment(transactionReference: string) {
    if ((window as any).squad) {
      const squadInstance = new (window as any).squad({

        onLoad: (v) => console.log('Widget loaded successfully ===>', v),

        onClose: (v) => {
          console.log('Widget closed ===>', v);
          this.processTransaction(transactionReference);
        },
        onSuccess: (v) => {
          console.log('Payment successful ===>', v);
          this.processTransaction(transactionReference);
        },
        key: environment.squadPublicKey,
        email: this.user.email,
        amount: this.order.total * 100,
        currency_code: 'NGN',
        transaction_ref: transactionReference
      });

      squadInstance.setup();
      squadInstance.open();
    } else {
      console.error("Squad widget not found");
    }
  }

  private createInvoiceAndPay(): void {
    this.proceeding = true;

    let apiCall;

    switch (this.order.serviceType) {
      case ServiceHead.ELECTRICITY:
        apiCall = this.electricityApiCall();
        break;
      case ServiceHead.AIRTIME:
        apiCall = this.airtimeApiCall();
        break;
      case ServiceHead.DATA:
        apiCall = this.dataApiCall();
        break;
      case ServiceHead.TV:
        apiCall = this.tvApiCall();
        break;
    }

    const sub = this.subscriptions.sink = apiCall
      .subscribe({
      next: (res) => {
        this.initiateSquadPayment(res.reference);
      }, error: () => {
      }
    });
    sub.add(() => this.proceeding = false);

  }

  private electricityApiCall(): Observable<PaymentInvoiceReference> {
    return this.paymentService.initiateSquadPayment({electricityOrderDetailsPojo: this.order});
  }

  private airtimeApiCall(): Observable<PaymentInvoiceReference> {
    return this.paymentService.initiateSquadAirtimePayment({airtimeOrderDetailsPojo: this.order});
  }

  private dataApiCall(): Observable<PaymentInvoiceReference> {
    return this.paymentService.initiateSquadDataPayment({dataOrderDetailsPojo: this.order});
  }

  private tvApiCall(): Observable<PaymentInvoiceReference> {
    return this.paymentService.initiateSquadTvPayment({tvOrderDetailsPojo: this.order});
  }

  private getUser(): void {
    this.authService.getUser().subscribe(user => this.user = user)
  }

  processTransaction(reference: string): void {
    this.router.navigate(['transactions', reference, 'details'], {state: {
        order: this.order
      }});
  }

  protected readonly ServiceHead = ServiceHead;

}
