import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserAccountService } from '../../services/user-account.service';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {

  constructor(private router: Router,
              private authenticationService: UserAccountService,
              private authService: AuthService
              ) { }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticationService.fetchUser()
      .pipe(map((user => {
        // console.log(user);
        if (!user) {
          this.router.navigate(['login']);
        }
        return !!user;
      })))
      .pipe(catchError((err: any, caught: Observable<any>) => {
        // this.authenticationService.lastProtectedUrl = state.url;
        this.router.navigate(['login']);
        return of(false);
      }));
  }
}
