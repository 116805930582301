import { Component, OnInit } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from '../../../shared/utils/unsubscribe-on-destroy-adapter';
import { Utils } from '../../../shared/utils/utils';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  BuyElectricityControllerService, ElectricityOrderDetailsPojo, ElectricityOrderDto,
  MeterPojo,
  MeterValidationControllerService
} from '../../../../../sdk/util-sdk';
import { Observable, Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MeterValidator } from '../../../shared/validators/meter.validator';
import { DiscoSelectorComponent } from '../disco-selector/disco-selector.component';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { BeneficiariesComponent } from '../../infra/beneficiaries/beneficiaries.component';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../../shared/shared.module';
import { EmComponent } from '../../../shared/components/em/em.component';
import { MoneyInputDirective } from '../../../shared/directives/money-input.directive';
import { CurrencyPipe } from '@angular/common';
import DiscoEnum = ElectricityOrderDto.DiscoEnum;

@Component({
    selector: 'app-electricity-order',
    templateUrl: './electricity-order.component.html',
    styleUrls: ['./electricity-order.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        SharedModule,
        ReactiveFormsModule,
        EmComponent,
        MoneyInputDirective,
        CurrencyPipe,
    ]
})
export class ElectricityOrderComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  tabData: tab[] = Utils.enumValues(tab);

  setActiveTab(tab: tab): void {
    this.activeTab = tab;
  }

  activeTab: tab = tab.Prepaid;

  form: FormGroup
  validatingMeter = false;
  validatingOrder = false;
  selectedMeter: MeterPojo | null = null;


  beneficiariesSubject: Subject<MeterPojo[]> = new Subject();
  beneficiaries$: Observable<MeterPojo[]> = this.beneficiariesSubject.asObservable();


  constructor(private fb: FormBuilder,
              private bsModalService: BsModalService,
              private meterValidator: MeterValidator,
              private meterService: MeterValidationControllerService,
              private electricityPurchaseService: BuyElectricityControllerService) {
    super();
  }

  preSelectAmounts = [1000, 2000, 3000, 5000, 10000, 20000]

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      disco: [DiscoEnum.AEDC],
      meterNumber: ['', Validators.compose([Validators.required])],
      amount: ['', Validators.compose([Validators.required])],
      tariff: ['']
    });

    this.form.controls.meterNumber.addAsyncValidators([this.meterValidator.meterExistsValidator(this.form)]);

    this.subscriptions.sink = this.meterValidator.getLatestValidationResponse().subscribe((response) => {
      this.selectedMeter = response;
    });

    this.subscriptions.sink = this.meterValidator.getValidationOngoing().subscribe((validatingMeter) => {
      this.validatingMeter = validatingMeter;
    });

    this.subscriptions.sink = this.form.controls.disco?.valueChanges.subscribe(() => {
      this.form.controls.meterNumber?.updateValueAndValidity();
    });

  }

  amountSelected(number: number) {
    return this.form.controls.amount.value == number;
  }

  selectAmount(amount: number) {
    this.form.patchValue({ amount });
  }


  selectDisco(): void {
    const ref = this.bsModalService.show(DiscoSelectorComponent, {
      initialState: {
        selectedDisco: this.form.controls.disco.value
      },
      class: 'modal-bottom-drawer',
    });

    ref.content.selectedDiscoChange.subscribe((disco) => {
      this.form.patchValue({ disco });
    });

  }

  discoIcon() {
    return Utils.discoIconPath(this.form.controls.disco.value);
  }

  discoName() {
    return Utils.discoName(this.form.controls.disco.value);
  }

  validateOrder(): void {

    if (this.validatingOrder) return;

    if (this.validatingMeter) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    this.validatingOrder = true;

    const sub = this.subscriptions.sink = this.electricityPurchaseService
      .validateElectricityOrder({ electricityOrderDto: this.getDto() })
      .subscribe({
        next: (order) => {
          this.showDetails(order);
        }, error: () => {}
      });

    sub.add(() => this.validatingOrder = false);

  }

  private getDto(): ElectricityOrderDto {
    return this.form.value;
  }

  showDetails(order: ElectricityOrderDetailsPojo): void {
    this.bsModalService.show(OrderDialogComponent, {
      initialState: {
        order
      },
      class: 'modal-bottom-drawer',
    });
  }

  private loadBeneficiaries(): void {
    this.meterService.getMeterBeneficiaries()
      .subscribe({
        next: (v: MeterPojo[]) => {
          this.beneficiariesSubject.next(v);
        }
      });
  }

  selectBeneficiary(): void {

    this.loadBeneficiaries();

    const ref = this.bsModalService.show(BeneficiariesComponent, {
      initialState: {
        beneficiaries$: this.beneficiaries$
      },
      class: 'modal-bottom-drawer',
    });

    ref.content.selectedBeneficiaryChange.subscribe((beneficiary) => {
      this.form.patchValue({ meterNumber: beneficiary.meterNumber });
    });

  }
}

enum tab {
  Prepaid = 'Prepaid',
  Postpaid = 'Postpaid',
}
