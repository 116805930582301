import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css'],
    standalone: true
})
export class NotFoundComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goBackHome() {
    if (true /* Check if logged in */) {
      this.router.navigate(['/dashboard'])
    }else{
      this.router.navigate(['/'])
    }
  }

}
