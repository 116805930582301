<div id="appCapsule">

  <div class="section mt-2 text-center">
    <h1>Log in</h1>
    <h4>Fill the form to log in</h4>
  </div>
  <div class="section mb-5 p-2">

    <form [formGroup]="form">
      <div class="card">
        <div class="card-body pb-1">
          <div class="form-group basic">
            <div class="input-wrapper">
              <label class="label" for="email1">Email</label>
              <input type="email" class="form-control" id="email1"
                     [formControlName]="'username'"
                     placeholder="Your email">
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-group basic">
            <div class="input-wrapper">
              <label class="label" for="password1">Password</label>
              <input type="password" class="form-control" id="password1" autocomplete="off"
                     [formControlName]="'password'"
                     placeholder="Your password">
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>
        </div>
      </div>
    </form>


      <div class="form-links mt-2">
        <div>
          <a (click)="signup()">Register Now</a>
        </div>
        <div><a (click)="resetPassword()" class="text-muted">Forgot Password?</a></div>
      </div>

      <div class="form-button-group  transparent">
        <button (click)="login()" class="btn btn-primary btn-block btn-lg">
          @if (signingIn) {
            <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
          }
          Log in</button>
      </div>

  </div>

</div>
