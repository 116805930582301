import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivationStart, NavigationEnd, NavigationStart, Router, RouterLink } from '@angular/router';
import { fromEvent, merge, of, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PageManager } from '../../../services/page-manager';
import { UserAccount } from '../../../models/account/user-account.model';
import { IonicModule } from '@ionic/angular';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { SharedModule } from '../../../shared/shared.module';
import { UserAccountService } from '../../../services/user-account.service';
import { User } from '../../../models/user/user.model';
import { UnsubscribeOnDestroyAdapter } from '../../../shared/utils/unsubscribe-on-destroy-adapter';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  standalone: true,
  imports: [
    IonicModule,
    SharedModule,
    SideNavComponent
  ]
})
export class HeaderComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {

  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  currentAccount: UserAccount;
  stop$ = new Subject();
  isHomeOrLanding: boolean = false;
  loading = false;
  title: string;
  user: User;

  constructor(
    private router: Router,
    private location: Location,
    private pageManager: PageManager,
    private authService: AuthService,
    private authenticationService: UserAccountService
  ) {
    super();
    router.events.forEach(data => {
      this.isHomeOrLanding = this.router.url === '/' || this.router.url === '/dashboard';
      if (data instanceof ActivationStart) {
        if (data) {
          this.title = data.snapshot?.data['title'];
        }
      } else if (data instanceof NavigationStart) {
        this.loading = true;
      } else if (data instanceof NavigationEnd) {
        this.loading = false;
      }
    });

  }


  ngOnInit(): void {
    this.hasNetworkConnection();

    this.pageManager.currentUserAccount$
      .subscribe(userAccount => {
        this.currentAccount = userAccount;
      });

    this.subscriptions.sink = this.authenticationService.getUser()
      .subscribe((user) => {
        this.user = user;
        if (this.currentAccount) {
          this.pageManager.currentUserAccount$.next(this.currentAccount);
        } else if (user) {
          if (user.accounts) {
            this.currentAccount = user.accounts[0];
            this.pageManager.setCurrentUserAccount(user.accounts[0]);
          }

        }
      });
  }


  hasNetworkConnection() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline')).pipe(map(() => navigator.onLine)).subscribe(status => {
      this.networkStatus = status;
    });
  }


  login(): void {
    this.router.navigate(['/login']);
  }

  back(): void {
    this.location.back();
  }

  logout(): void {
    this.authService.logout();
  }
}
