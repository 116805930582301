import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserAccountService } from '../../services/user-account.service';
declare var Splide: any


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  imports: [
    IonicModule
  ],
  standalone: true
})
export class LandingComponent  implements OnInit {

  splideInstance;

  constructor(private router: Router,
              private authService: UserAccountService) { }

  ngOnInit() {
    this.initializeSplide();
  }

  initializeSplide(): void {
    if (this.splideInstance) {
      this.splideInstance.destroy();
    }
    this.splideInstance = new Splide('.carousel-single', {
      perPage: 3,
      rewind: true,
      type: "loop",
      gap: 16,
      padding: 16,
      arrows: false,
      pagination: false,
      breakpoints: {
        768: {
          perPage: 1
        },
        991: {
          perPage: 2
        }
      }
    }).mount();
  }

  signup(): void {
    this.router.navigate(['/signup']);
  }

  login(): void {
    this.router.navigate(['/login']);
  }
}
