<div id="appCapsule">
  <div class="section mt-2 text-center">
    <h2>Register now</h2>
  </div>
  <div class="section mb-5 p-2">

    <div class="card">
      <div class="card-body">

        <form [formGroup]="form">

          <div class="form-group basic">
            <div class="input-wrapper">
              <label class="label">First Name</label>
              <input
                [type]="'text'"
                [formControlName]="'firstName'"
                class="form-control" placeholder="Your first name"
              >
              <i class="clear-input">
                <ion-icon name="close-circle" />
              </i>
            </div>
            <error-message
              [controlName]="'firstName'"
              [singularError]="true"
              [form]="form"
            />
          </div>

          <div class="form-group basic">
            <div class="input-wrapper">
              <label class="label">Last Name</label>
              <input
                [type]="'text'"
                [formControlName]="'lastName'"
                class="form-control" placeholder="Your last name"
              >
              <i class="clear-input">
                <ion-icon name="close-circle" />
              </i>
            </div>
            <error-message
              [controlName]="'lastName'"
              [singularError]="true"
              [form]="form"
            />
          </div>

          <div class="form-group basic">
            <div class="input-wrapper">
              <label class="label">Phone Number</label>
              <ngx-intl-tel-input
                class="form-control w-100 d-flex"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[
                        SearchCountryField.Iso2,
                        SearchCountryField.Name
                      ]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="selectedCountryISO"
                [separateDialCode]="separateDialCode"
                [numberFormat]="PhoneNumberFormat.National"
                [maxLength]="15"
                [phoneValidation]="true"
                [formControlName]="'phoneNumber'"
              ></ngx-intl-tel-input>
            </div>
            <error-message
              [controlName]="'phoneNumber'"
              [singularError]="true"
              [form]="form"
            />
          </div>

          <div class="form-group basic">
            <div class="input-wrapper">
              <label class="label">Email</label>
              <input
                [formControlName]="'email'"
                [type]="'email'" class="form-control" placeholder="Your email">
              <i class="clear-input">
                <ion-icon name="close-circle" />
              </i>
            </div>
            <error-message
              [controlName]="'email'"
              [singularError]="true"
              [form]="form"
            />
          </div>

          <password
            (password)="password($event)"
          />

          <div class="custom-control custom-checkbox fontsize-sub mt-2 mb-1">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="customCheckb1">
              <label class="form-check-label" for="customCheckb1">
                I have read, understood and agreed to the <a href="#" data-bs-toggle="modal" data-bs-target="#termsModal">Terms & Conditions</a> and <a href="#" data-bs-toggle="modal" data-bs-target="#privacyPolicyModal">Privacy Policy</a>
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="mt-3 transparent">
      <button (click)="submit(); false;" class="btn btn-primary btn-block btn-lg">
        @if (submitting) {
          <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
        }
        Sign Up
      </button>
    </div>

  </div>
</div>
