<div id="appCapsule">
  <div class="section full mt-3 mb-3">
      <div class="carousel-single splide">
      <div class="splide__track">
        <ul class="splide__list">

          <li class="splide__slide">
            <div class="card">
              <img src="assets/img/wide1.jpg" class="card-img-top" alt="image">
              <div class="card-body">
                <h5 class="card-title">Carousel Item</h5>
                <p class="card-text">
                  Some quick example text to build on the card title and make up the bulk
                  of the card's content.
                </p>
              </div>
            </div>
          </li>

          <li class="splide__slide">
            <div class="card">
              <img src="assets/img/wide1.jpg" class="card-img-top" alt="image">
              <div class="card-body">
                <h5 class="card-title">Item Title</h5>
                <p class="card-text">
                  Some quick example text to build on the card title and make up the bulk
                  of the card's content.
                </p>
              </div>
            </div>
          </li>

          <li class="splide__slide">
            <div class="card">
              <img src="assets/img/wide1.jpg" class="card-img-top" alt="image">
              <div class="card-body">
                <h5 class="card-title">Another Item Title</h5>
                <p class="card-text">
                  Some quick example text to build on the card title and make up the bulk
                  of the card's content.
                </p>
              </div>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>

  <div class="fixed-bar">
    <div class="row">
      <div class="col-6">
        <a (click)="login()" class="btn btn-lg btn-outline-secondary btn-block goBack">Login</a>
      </div>
      <div class="col-6">
        <a (click)="signup()" class="btn btn-lg btn-primary btn-block">Sign Up</a>
      </div>
    </div>
  </div>
</div>
