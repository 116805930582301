import { Component, Input, OnInit } from '@angular/core';
import {
  AirtimeOrderDetailsPojo,
  DataOrderDetailsPojo,
  ElectricityOrderDetailsPojo,
  TransactionPojo, TvOrderDetailsPojo
} from '../../../../../sdk/util-sdk';
import { AirtimeOrderDetailsComponent } from '../../airtime/airtime-order-details/airtime-order-details.component';
import { CurrencyPipe } from '@angular/common';
import { DataOrderDetailsComponent } from '../../data/data-order-details/data-order-details.component';
import { IonicModule } from '@ionic/angular';
import { OrderDetailsComponent } from '../../electricity/order-details/order-details.component';
import {
  TelevisionOrderDetailsComponent
} from '../../television/television-order-details/television-order-details.component';
import { TransactionsHelper } from '../TransactionsHelper';
import ServiceHead = TransactionPojo.ServiceHeadEnum;


@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
  imports: [
    AirtimeOrderDetailsComponent,
    CurrencyPipe,
    DataOrderDetailsComponent,
    IonicModule,
    OrderDetailsComponent,
    TelevisionOrderDetailsComponent
  ],
  standalone: true
})
export class ReceiptComponent  implements OnInit {

  @Input() transaction: TransactionPojo;

  constructor() { }

  ngOnInit(): void {
    this.transaction = history?.state?.transaction;
  }


  get electricity(): ElectricityOrderDetailsPojo {
    return this.transaction?.electricityOrderDetails;
  }

  get airtime(): AirtimeOrderDetailsPojo {
    return this.transaction?.airtimeOrderDetails;
  }

  get data(): DataOrderDetailsPojo {
    return this.transaction?.dataOrderDetails;
  }

  get television(): TvOrderDetailsPojo {
    return this.transaction?.tvOrderDetails;
  }

  get icon(): string {
    return TransactionsHelper.getProviderIcon(this.transaction);
  }

  get name(): string {
    return TransactionsHelper.name(this.transaction);
  }

  get status(): string {
    return TransactionsHelper.transactionStatus(this.transaction);
  }


  get statusIcon(): string {
    return TransactionsHelper.statusIcon(this.transaction);
  }

  get statusColor(): string {
    return TransactionsHelper.statusColor(this.transaction);
  }

  protected readonly ServiceHead = ServiceHead;


}
