import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    IonicModule,
    ReactiveFormsModule
  ],
  standalone: true
})
export class LoginComponent  implements OnInit {

  form: FormGroup;
  signingIn = false;

  constructor(private fb: FormBuilder,
              private router: Router,
              private authService: AuthService) { }

  ngOnInit() {
    this.initiateForm();
  }

  initiateForm() {
    this.form = this.fb.group({
      username: [''],
      password: ['']
    });
  }

  login(): void {

    if (this.form.invalid) {
      console.log('invalid form');
      return;
    }
    console.log('clicked');

    this.signingIn = true;

    this.authService.login(this.form.value?.username, this.form.value?.password, 'dashboard').subscribe({
      next: (v) => {
        console.log(v)
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        this.signingIn = false;
      }
    });
  }

  signup(): void {
    this.router.navigate(['/signup']);
  }

  resetPassword(): void {
  }
}
