import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { MeterPojo } from '../../../../../sdk/util-sdk';
import { AsyncPipe } from '@angular/common';
import { UnsubscribeOnDestroyAdapter } from '../../../shared/utils/unsubscribe-on-destroy-adapter';
import { distinctUntilChanged } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-beneficiaries',
    templateUrl: './beneficiaries.component.html',
    styleUrls: ['./beneficiaries.component.scss'],
    standalone: true,

})
export class BeneficiariesComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() selectedBeneficiary: MeterPojo;

  @Input() beneficiaries$: Observable<MeterPojo[]>;

  @Output() selectedBeneficiaryChange: EventEmitter<MeterPojo> = new EventEmitter;

  beneficiaries: MeterPojo[];

  constructor(private modalRef: BsModalRef) {
    super();
  }

  ngOnInit() {
    this.init();
  }

  init(): void {
    this.beneficiaries$
      .pipe(distinctUntilChanged())
      .subscribe(v => {
        this.beneficiaries = v;
    });
  }

  close(): void {
    this.modalRef.hide();
  }

  selectBeneficiary(beneficiary: MeterPojo) {
    this.selectedBeneficiaryChange.emit(beneficiary);
    this.close();
  }
}
