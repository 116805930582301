import { Component, Input, OnInit } from '@angular/core';
import { AirtimeOrderDetailsPojo, DataOrderDetailsPojo, ServicePlan, TransactionPojo } from '../../../../../sdk/util-sdk';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AirtimeOrderDetailsComponent } from '../airtime-order-details/airtime-order-details.component';
import ServiceHead = TransactionPojo.ServiceHeadEnum;
import { DataOrderDetailsComponent } from '../../data/data-order-details/data-order-details.component';
import {
  TelevisionOrderDetailsComponent
} from '../../television/television-order-details/television-order-details.component';

@Component({
    selector: 'app-order-dialog',
    templateUrl: './order-dialog.component.html',
    styleUrls: ['./order-dialog.component.scss'],
    standalone: true,
     imports: [
      AirtimeOrderDetailsComponent,
      DataOrderDetailsComponent,
      TelevisionOrderDetailsComponent
    ]
})
export class OrderDialogComponent  implements OnInit {

  @Input() order: any | AirtimeOrderDetailsPojo | DataOrderDetailsPojo;

  constructor(private modalRef: BsModalRef,
              private router: Router) { }

  ngOnInit(): void {
  }

  close(): void {
    this.modalRef.hide();
  }

  selectPaymentMethod(): void {
    this.router.navigate(['/airtime/pay'], {
      state: {
        order: this.order
      }
    })
    this.close();
  }

  protected readonly ServicePlan = ServicePlan;
  protected readonly ServiceHead = ServiceHead;
}
