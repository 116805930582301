import { Component, OnInit } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from '../../../shared/utils/unsubscribe-on-destroy-adapter';
import { Utils } from '../../../shared/utils/utils';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  AirtimeOrderDetailsPojo,
  ServicePlan,
  MeterPojo,
  MeterValidationControllerService, State
} from '../../../../../sdk/util-sdk';
import { Observable, Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BeneficiariesComponent } from '../../infra/beneficiaries/beneficiaries.component';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../../shared/shared.module';
import { EmComponent } from '../../../shared/components/em/em.component';
import { MoneyInputDirective } from '../../../shared/directives/money-input.directive';
import { CurrencyPipe, TitleCasePipe } from '@angular/common';
import ProviderEnum = AirtimeOrderDetailsPojo.ProviderEnum;
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import ServiceHeadEnum = ServicePlan.ServiceHeadEnum;

@Component({
  selector: 'app-airtime-order',
  templateUrl: './airtime-order.component.html',
  styleUrls: ['./airtime-order.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    SharedModule,
    ReactiveFormsModule,
    EmComponent,
    MoneyInputDirective,
    CurrencyPipe,
    TitleCasePipe
  ]
})
export class AirtimeOrderComponent extends UnsubscribeOnDestroyAdapter implements OnInit {


  providers: ProviderEnum[] = Utils.enumValues(ProviderEnum)
    .filter(p => p != ProviderEnum.SMILE && p != ProviderEnum.SPECTRANET);

  form: FormGroup
  validatingPhoneNumber = false;
  validatingOrder = false;


  beneficiariesSubject: Subject<MeterPojo[]> = new Subject();
  beneficiaries$: Observable<MeterPojo[]> = this.beneficiariesSubject.asObservable();


  selectProvider(provider: string): void {
    this.form.controls.provider.setValue(provider);
  }

  get selectedProvider(): string {
    return this.form.controls.provider.value;
  }


  constructor(private fb: FormBuilder,
              private bsModalService: BsModalService,
              private meterService: MeterValidationControllerService) {
    super();
  }

  preSelectTotals = [1000, 2000, 3000, 5000, 10000, 20000]

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      provider: [ProviderEnum.MTN, Validators.compose([Validators.required])],
      phoneNumber: ['', Validators.compose([Validators.required])],
      total: ['', Validators.compose([Validators.required])]
    });

  }

  totalSelected(number: number) {
    return this.form.controls.total.value == number;
  }

  selectTotal(total: number) {
    this.form.patchValue({ total });
  }


  viewOrder(): void {

    if (this.form.invalid) {
      return;
    }

    console.log(this.getDto());

    this.showDetails(this.getDto());

  }


  private getDto(): AirtimeOrderDetailsPojo {
    const data: AirtimeOrderDetailsPojo = this.form.getRawValue();
    data.serviceType = ServiceHeadEnum.AIRTIME;
    return data;
  }

  showDetails(order: AirtimeOrderDetailsPojo): void {
    this.bsModalService.show(OrderDialogComponent, {
      initialState: {
        order
      },
      class: 'modal-bottom-drawer',
    });
  }

  private loadBeneficiaries(): void {
    this.meterService.getMeterBeneficiaries()
      .subscribe({
        next: (v: MeterPojo[]) => {
          this.beneficiariesSubject.next(v);
        }
      });
  }

  selectBeneficiary(): void {

    this.loadBeneficiaries();

    const ref = this.bsModalService.show(BeneficiariesComponent, {
      initialState: {
        beneficiaries$: this.beneficiaries$
      },
      class: 'modal-bottom-drawer',
    });

    ref.content.selectedBeneficiaryChange.subscribe((beneficiary) => {
      this.form.patchValue({ meterNumber: beneficiary.meterNumber });
    });

  }

  icon(disco: AirtimeOrderDetailsPojo.ProviderEnum): string {
    return Utils.networkIconPath(disco);
  }

  name(disco: AirtimeOrderDetailsPojo.ProviderEnum): string {
    return Utils.networkName(disco);
  }
}

enum tab {
  Prepaid = 'Prepaid',
  Postpaid = 'Postpaid',
}
