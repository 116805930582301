import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from "./components/navigation/header/header.component";
import { MenuComponent } from './components/navigation/menu/menu.component';
import { UserAccountService } from './services/user-account.service';
import { User } from './models/user/user.model';
import { UnsubscribeOnDestroyAdapter } from './shared/utils/unsubscribe-on-destroy-adapter';
import { addIcons } from 'ionicons';
import {
  menuOutline,
  homeOutline,
  documentTextOutline,
  settingsOutline,
  closeOutline,
  pieChartOutline,
  cardOutline,
  moonOutline,
  chevronBackOutline,
  chevronForwardOutline,
  notificationsOutline,
  appsOutline,
  refreshOutline,
  personCircle,
  closeCircleOutline
} from 'ionicons/icons';


@Component({
    selector: 'app-root',
    imports: [
      RouterOutlet,
      HeaderComponent,
      MenuComponent
    ],
    standalone: true,
    templateUrl: './app.component.html',
    styleUrl: './app.component.css'
})
export class AppComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  title = 'util-ui';
  user: User

  constructor(private authenticationService: UserAccountService) {
    super();
    addIcons({
      'menu-outline': menuOutline,
      'home-outline': homeOutline,
      'document-text-outline': documentTextOutline,
      'settings-outline': settingsOutline,
      'close-outline': closeOutline,
      'close-circle-outline': closeCircleOutline,
      'pie-chart-outline': pieChartOutline,
      'card-outline': cardOutline,
      'moon-outline': moonOutline,
      'chevron-back-outline': chevronBackOutline,
      'notifications-outline': notificationsOutline,
      'apps-outline': appsOutline,
      'refresh-outline': refreshOutline,
      'chevron-forward-outline': chevronForwardOutline,
      'person-circle': personCircle
    })
  }

  ngOnInit(): void {
    this.subscriptions.sink = this.authenticationService.getUser().subscribe(user => {
      this.user = user;
    });
  }


  get isAuthenticated(): boolean {
    return this.user !== null && this.user !== undefined;
  }

}
