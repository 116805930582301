import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CountryISO, NgxIntlTelInputModule, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { telePhoneNumberValidator } from '../../../shared/utils/utils';
import { PasswordComponent } from '../../../shared/components/password/password/password.component';
import { UserControllerService } from '../../../../../sdk/util-sdk';
import { EmComponent } from '../../../shared/components/em/em.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SuccessDialogComponent } from '../../../shared/dialogs/success-dialog/success-dialog.component';
import { Router } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    standalone: true,
    imports: [
        SharedModule,
        IonicModule,
        NgxIntlTelInputModule,
        ReactiveFormsModule,
        PasswordComponent,
        EmComponent
    ]
})
export class SignupComponent  implements OnInit {

  constructor(private fb: FormBuilder,
              private router: Router,
              private modalService: BsModalService,
              private userService: UserControllerService) { }

  ngOnInit() {
    this.initForm();
  }

  form: FormGroup;
  submitting: boolean = false;

  initForm(): void {
    this.form = this.fb.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.email,
        Validators.minLength(2)
      ])],
      phoneNumber: ['', [telePhoneNumberValidator()]],
      password: ['', Validators.required]
    });
  }

  password($event): void {
    this.form.patchValue({ password: $event });
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    let signupDto = this.form.value;
    console.log(signupDto.phoneNumber);
    signupDto.phoneNumber = signupDto.phoneNumber?.e164Number

    this.submitting = true;
    this.userService.registerUser({ signupDto }).subscribe({
      next: () => {
        const ref = this.modalService.show(SuccessDialogComponent, {
          initialState: {
            title: 'Success',
            message: 'User registered successfully'
          },
          class: 'modal-bottom-drawer'
        });

        ref.content.doneEvent.subscribe(() => {
          this.router.navigate(['/dashboard']);
        });
      },
      error: (error) => {
        console.error('Error registering user', error);
      }
    }).add(() => this.submitting = false);
  }

  protected readonly SearchCountryField = SearchCountryField;
  protected readonly PhoneNumberFormat = PhoneNumberFormat;
  separateDialCode: boolean = false;
  selectedCountryISO: any = CountryISO['Nigeria'];
}
