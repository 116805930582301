import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
  APP_INITIALIZER, inject
} from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {HttpInterceptorService} from "./services/http-interceptor.service";
import {UserAccountService} from "./services/user-account.service";
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import {environment} from "./environments/environment";
import {filter, mergeMap} from "rxjs/operators";
import { provideIonicAngular } from '@ionic/angular/standalone';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BASE_PATH } from '../../sdk/util-sdk';
import { CookieService } from 'ngx-cookie-service';
import { DarkModeService } from './services/dark-mode.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptorsFromDi()),
    provideZoneChangeDetection({eventCoalescing: true}),
    KeycloakService,
    CookieService,
    {
      provide: BASE_PATH,
      useValue: environment.siteUrl
    },
    // provideAppInitializer(() => {
    //     const initializerFn = (initializeKeycloak)(inject(KeycloakService), inject(AuthenticationService));
    //     return initializerFn();
    //   }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeDarkMode(inject(DarkModeService)),
      deps: [DarkModeService],
      multi: true
    },
    importProvidersFrom([BrowserAnimationsModule]),
    provideIonicAngular({})
  ]
};

function initializeDarkMode(darkModeService: DarkModeService) {
  return () => {
    const isDarkMode = darkModeService.isDarkModeEnabled();
    darkModeService.toggleDarkMode(isDarkMode);
  };
}
