import { Routes } from '@angular/router';
import {
  ElectricityComponent
} from './components/electricity/electricity.component';
import { SignupComponent } from './components/signup/signup/signup.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import {
  PaymentMethodComponent
} from './components/electricity/payment-method/payment-method.component';
import { HomeComponent } from './components/home/home.component';
import { TransactionComponent } from './components/electricity/transaction/transaction.component';
import { NotLoggedInGuard } from './shared/guards/not-logged-in.guard';
import {
  ElectricityOrderComponent
} from './components/electricity/electricity-order/electricity-order.component';
import { AirtimeComponent } from './components/airtime/airtime.component';
import { AirtimeOrderComponent } from './components/airtime/airtime-order/airtime-order.component';
import { TransactionsListComponent } from './components/transactions/transactions-list/transactions-list.component';
import {
  TransactionDetailsComponent
} from './components/transactions/transaction-details/transaction-details.component';
import { DataComponent } from './components/data/data.component';
import { DataOrderComponent } from './components/data/data-order/data-order.component';
import { TelevisionComponent } from './components/television/television.component';
import { TelevisionOrderComponent } from './components/television/television-order/television-order.component';
import { ReceiptComponent } from './components/transactions/receipt/receipt.component';
import { NotFoundComponent } from './components/error-pages/not-found/not-found.component';
import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { LoggedInGuard } from './shared/guards/logged-in.guard';
import { SettingsComponent } from './components/settings/settings.component';

export const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    canActivate: [NotLoggedInGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotLoggedInGuard]
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [NotLoggedInGuard]
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./components/home/home.component').then((c) => c.HomeComponent),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'airtime',
    loadComponent: () => import('./components/airtime/airtime.component').then((c) => c.AirtimeComponent),
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        title: 'Airtime',
        component: AirtimeOrderComponent
      },
      {
        path: 'pay',
        component: PaymentMethodComponent,
        title: 'Payment Method'
      }
    ]
  },
  {
    path: 'data',
    loadComponent: () => import('./components/data/data.component').then((c) => c.DataComponent),
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        title: 'Data',
        component: DataOrderComponent
      }
    ]
  },
  {
    path: 'television',
    loadComponent: () => import('./components/television/television.component').then((c) => c.TelevisionComponent),
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        component: TelevisionOrderComponent,
        data: {
          title: 'Television'
        }

      }
    ]
  },
  {
    path: 'electricity',
    loadComponent: () => import('./components/electricity/electricity.component').then((c) => c.ElectricityComponent),
    canActivate: [LoggedInGuard],
    data: {
      title: 'Electricity'
    },
    children: [
      {
        path: '',
        component: ElectricityOrderComponent,

      },
      {
        path: 'pay',
        component: PaymentMethodComponent,
        title: 'Payment Method'
      },
      {
        path: 'transaction/:reference',
        component: TransactionComponent,
        title: 'Transaction'
      }
    ]
  },
  {
    path: 'transactions',
    loadComponent: () => import('./components/transactions/transactions.component').then((c) => c.TransactionsComponent),
    canActivate: [LoggedInGuard],
    data: {
      title: 'Transactions'
    },
    children: [
      {
        path: '',
        component: TransactionsListComponent
      },
      {
        path: ':reference/details',
        component: TransactionDetailsComponent,
        data: {
          title: 'Transaction Details'
        }
      },
      {
        path: ':reference/receipt',
        component: ReceiptComponent,
        data: {
          title: 'Transaction Receipt'
        }
      }
    ]
  },
  {
    path: 'settings',
    loadComponent: () => import('./components/settings/settings.component').then((c) => c.SettingsComponent),
    canActivate: [LoggedInGuard]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];
